<template>
    <div>
        <!-- default -->
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
            v-if="defaultText"
        >
        {{ title }}
        </v-alert>

        <!-- dostava -->
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
            v-if="userTypeDelivery"
        >
        {{ user_type_delivery_title }}
        <div style="min-height:10px;"></div>
        <ul>
            <li>{{ user_type_delivery_text1 }}</li>
            <li>{{ user_type_delivery_text2 }}</li>
            <li>{{ user_type_delivery_text3 }}</li>
        </ul>
        </v-alert>

        <!-- Izvajanje del -->
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
            v-if="userTypeContractor"
        >
        {{ user_type_contractor_title }}
        <div style="min-height:10px;"></div>
        <ul>
            <li v-for="(user_type_contractor_text, index) in user_type_contractor_texts" :key="index">{{ user_type_contractor_text }}</li>
        </ul>
        </v-alert>
    </div>
</template>

<script>

export default {
    props: ['user_type'],
    name: 'promenada-note-info-user-type',
    data: () => ({
        title: 'Vnesite opombe ipd.',

        user_type_delivery_title: 'Izbrabni tip dovolilnice - dostava. Pod opombe dopišite:',
        user_type_delivery_text1: 'komu dostavljate',
        user_type_delivery_text2: 'ob katerih dnevih dostavljate',
        user_type_delivery_text3: 'ob katerih urah se vrši dostava',

        user_type_contractor_title: 'Izbrani tip dovolilnice - izvajanje del. Pod opombe dopišite: ',
        user_type_contractor_texts: [
            'na katerem objektu izvajate dela',
            'kdo je naročnik del in njegov kontakt',
            'kakšna dela izvajate',
            'od katerega do katerega datum predvidoma izvajate dela',
            'ob katerih urah se izvajajo dela'
        ]


    }),

    computed: {
        defaultText() {
            if(this.user_type == null || this.user_type == '' || this.user_type == '935e2be5-7bcf-4efe-a61a-a46f3638d637' || this.user_type == '135ef457-a72d-4ca5-b146-69391afdb35f' || this.user_type == '0b9705aa-0a0b-4823-9e06-57be479deab7') {
                return true
            }

            return false
        },

        userTypeDelivery() {
            if(this.user_type == '6d5094c0-da96-4de5-98f5-a802e815693b') {
                return true
            }

            return false
        },

        userTypeContractor() {
            if(this.user_type == 'f79d5642-9603-4b45-9da1-4ad8249c7b0a') {
                return true
            }

            return false
        }        
    }

}

</script>